import React, { Component } from "react"
import "./style.scss"
import FormButton from "@components/_atoms/button_form_input_submit"
import InputLabelState from "@components/_molecules/input_label_state"
import * as data from "@data/pledge"

const Checkbox = ({ label, ...attributes }) => (
  <div className="checkbox">
    <label>
      <input {...attributes} />
      {label}
    </label>
  </div>
)

export default Checkbox
