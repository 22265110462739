import React from "react"

import SEO from "@components/seo"
import * as data from "@data/pledge"
import Layout from "@components/layout"
import HeaderAndText from "@components/header_with_text"
import BackButton from "@components/back_button"
import StatementVerifcation from "@components/_molecules/statement_verification"
import ProgressBar from "@components/_atoms/progress_bar_reg"

const InvestVerifyPage = () => {
  const { verify } = data
  return (
    <Layout pageClass="pledge" type="gray">
      <SEO title={verify.title} />
      <ProgressBar />
      <BackButton data={verify.backLink} />
      <HeaderAndText data={verify.heading} />
      <StatementVerifcation />
    </Layout>
  )
}

export default InvestVerifyPage
