import React, { Component } from "react"
import "./style.scss"
import axios from "axios"
import * as headers from "@data/headers"
import FormButton from "@components/_atoms/button_form_input_submit"
import Checkbox from "@components/_atoms/button_checkbox"
import InputLabelState from "@components/_molecules/input_label_state"
import GeneralSubmitMessage from "@components/_atoms/general_submit_message"
import { SlideDown } from "react-slidedown"
import "@components/_atoms/_style/slidedown.scss"
import * as progress from "@services/progress"
import * as errors from "@services/errors"
import LAEJS from "@services/LAEJS"

class StatementVerifcation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      affiliated: "",
      affiliatedIsChecked: false,
      publicCompany: "",
      publicCompanyIsChecked: false,
      politicalFigure: "",
      politicalFigureIsChecked: false,
      noneIsChecked: false,
      verifyError: false,
      employer: "",
      employerHelpText: "",
      employerErrorStatus: "",
      company: "",
      companyHelpText: "",
      companyErrorStatus: "",
      stock: "",
      stockHelpText: "",
      stockErrorStatus: "",
      country: "",
      countryHelpText: "",
      countryErrorStatus: "",
      generalError: null,
      buttonSubmitted: false
    }
  }

  componentDidMount() {
    LAEJS.track("screen_view.brokerage_status")
    let { broker, directorOf, stock, politicallyExposedNames } = localStorage
    if (!broker && !directorOf && !politicallyExposedNames) {
      this.setState({ noneIsChecked: true })
    } else {
      this.setState({
        employer: broker ? broker : ``,
        affiliatedIsChecked: broker !== "",
        company: directorOf ? directorOf : ``,
        stock: stock ? stock : ``,
        publicCompanyIsChecked: directorOf !== "",
        country: politicallyExposedNames || ``,
        politicalFigureIsChecked: politicallyExposedNames !== "",
        noneIsChecked: false
      })
    }
  }

  toggleCheckboxChange = ev => {
    if (ev.target.name === "noneIsChecked" && ev.target.checked === true) {
      //if 'none' is checked, uncheck all except none
      this.setState({
        affiliatedIsChecked: false,
        publicCompanyIsChecked: false,
        politicalFigureIsChecked: false,
        noneIsChecked: true
      })
    } else {
      //otherwise, check the option chosen and ensure 'none' is unchecked
      this.setState({
        [ev.target.name]: ev.target.checked,
        noneIsChecked: false
      })
    }
  }

  handleChange = ev => {
    this.setState({
      [ev.target.name]: ev.target.value,
      [`${ev.target.name}ErrorStatus`]: false
    })
  }

  handleSubmit = ev => {
    this.setState({ buttonSubmitted: true })
    ev.preventDefault()
  }

  errorHandler = err => {
    this.setState({
      buttonSubmitted: false,
      generalError: errors.messaging(err)
    })
  }

  isDisabled = () => {
    if (this.state.verify === "") {
      return true
    } else {
      return false
    }
  }

  render() {
    return (
      <div className="verify-statements">
        <form onSubmit={this.handleSubmit.bind(this)}>
          <Checkbox
            type="checkbox"
            name="affiliatedIsChecked"
            checked={this.state.affiliatedIsChecked}
            onChange={this.toggleCheckboxChange}
            label={"I’m affiliated or work with a broker-dealer or FINRA"}
          />
          <SlideDown closed={!this.state.affiliatedIsChecked ? true : false}>
            <InputLabelState
              data={{
                name: `Employer`,
                type: `text`,
                helpText: this.state.employerHelpText,
                errorStatus: this.state.employerErrorStatus,
                defaultVisibility: 0,
                value: this.state.employer
              }}
              onChange={this.handleChange}
            />
          </SlideDown>
          <Checkbox
            type="checkbox"
            name="publicCompanyIsChecked"
            checked={this.state.publicCompanyIsChecked}
            onChange={this.toggleCheckboxChange}
            label={
              "I’m a director, officer, or 10% shareholder of a public company"
            }
          />
          <SlideDown closed={!this.state.publicCompanyIsChecked ? true : false}>
            <InputLabelState
              data={{
                name: `Company`,
                type: `text`,
                helpText: this.state.companyHelpText,
                errorStatus: this.state.companyErrorStatus,
                defaultVisibility: 0,
                value: this.state.company
              }}
              onChange={this.handleChange}
            />
            <InputLabelState
              data={{
                name: `Stock`,
                type: `text`,
                helpText: this.state.stockHelpText,
                errorStatus: this.state.stockErrorStatus,
                defaultVisibility: 0,
                value: this.state.stock
              }}
              onChange={this.handleChange}
            />
          </SlideDown>
          <Checkbox
            type="checkbox"
            name="politicalFigureIsChecked"
            checked={this.state.politicalFigureIsChecked}
            onChange={this.toggleCheckboxChange}
            label={"I’m a senior foreign political figure"}
          />

          <SlideDown
            closed={!this.state.politicalFigureIsChecked ? true : false}
          >
            <InputLabelState
              data={{
                name: `Country`,
                type: `text`,
                helpText: this.state.countryHelpText,
                errorStatus: this.state.countryErrorStatus,
                defaultVisibility: 0,
                value: this.state.country
              }}
              onChange={this.handleChange}
            />
          </SlideDown>
          <Checkbox
            id="popular"
            type="checkbox"
            name="noneIsChecked"
            checked={this.state.noneIsChecked}
            onChange={this.toggleCheckboxChange}
            label={"None of the above"}
          />
          <div style={{ width: `100%`, height: `3rem` }}></div>
          <p className={this.state.verifyError ? `has-error` : `hidden`}>
            Missing value
          </p>

          <GeneralSubmitMessage error={this.state.generalError} />
          <FormButton
            isDisabled={
              (!this.state.affiliatedIsChecked &&
                !this.state.publicCompanyIsChecked &&
                !this.state.politicalFigureIsChecked &&
                !this.state.noneIsChecked) ||
              this.state.buttonSubmitted
            }
          />
        </form>
      </div>
    )
  }
}

export default StatementVerifcation
